export default {
	pageTitle: 'Cybersecurity | Department for International Trade',
	pageDescription: `Secure identification in a digital world. Why UK tech leads the way in cybersecurity`,
	heroSectionOne: {
		main: {
			title: 'Secure identification in a digital world ',
			author: 'WRITTEN BY',
			brandPath: 'images/EBCONPRGB.svg',
			content: `
			<p>
				Why UK tech leads the way in cybersecurity
			</p>
		`
		},
		banner: {
			firstImg: 'images/Hero_shutterstock_703871917.jpg',
			secondImg: 'images/svg/square-gradient.svg'
		},
	},
	sliderSectionOne: {
		headerImgPath: 'images/innovation-is-great.png',
		headerImgAlt: 'Innovation is great img alt',
		transparentBg: true,
		settings: {
			dots: false,
			arrows: false,
			infinite: false,
			speed: 500,
			slidesToShow: 3,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 2.2,
						slidesToScroll: 1,
						draggable: true
					}
				}, {
					breakpoint: 767,
					settings: {
						slidesToShow: 1.2,
						slidesToScroll: 1,
						draggable: true,
						arrows: true
					}
				}
			]
		},
		items: [
			{
				img: 'images/homepage_fintech.jpg',
				title: 'Fintech',
				text: 'The advent of open banking and emerging fintech community has shifted the UK towards a pro-innovation mindset.',
				more: {
					label: 'Read more',
					link: '/story/fintech/'
				}
			}, {
				img: 'images/home_smart_cities.jpg',
				title: 'Smart cities',
				text: 'From nuclear fusion and smart buildings to electric vehicles, UK companies are spearheading the clean-tech revolution.',
				more: {
					label: 'Read more',
					link: '/story/smart-cities/'
				}
			}, {
				img: 'images/home_edtech.jpg',
				title: 'Edtech',
				text: 'Education was once lagging in digital innovation, but this is all changing. UK-based edtech startup, Twig Education, is blending learning and technology to bring subjects to life. ',
				more: {
					label: 'Read more',
					link: '/story/edtech/'
				}
			}
		]
	},
	cardSectionOne: {
		position: 'left',
		bgImg: 'images/svg/square-gradient.svg',
		main: {
			title: '',
			content: `<p>Verifying and sharing our identity is critical to the most important transactions in our personal 
and business lives, from buying a house to getting a job. For consumers, sharing sensitive information with organisations 
leaves lingering concerns about data breaches, cyberattacks and identity fraud. For intermediaries such as letting agents 
and law firms, failing to do their authentication due diligence can leave them exposed to serious sanctions and fines 
for falling short of rules around money laundering and financial crime. For employers and recruiters in sectors such as 
education and healthcare, failing to pick up on adverse information, right to work or criminal convictions could put 
vulnerable people at risk.</p>`
		},
		additional: {
			img: 'images/In_article_01.jpg',
			comment: {
				text: 'We get a chance to scale up our technology and the public sector gets to deploy solutions swiftly.',
				subtext: 'Callum Murray, CEO of Amiqus'
			}
		},
	},
	cardSectionTwo: {
		position: 'left',
		bgImg: 'images/svg/square-gradient.svg',
		main: {
			title: 'Trade and investment challenges and opportunities in the post-pandemic world',
			content: `<p>The pandemic has transformed the global trade and investment landscape, but 
offers unique opportunities to agile and resilient businesses in sectors of the future.</p>`
		},
		additional: {
			img: 'images/homepage_chart.jpg',
		},
		more: {
			label: 'Read More',
			link: '/briefing-paper/'
		}
	},
	contentOne: `
		<p>
			“All of these are linked to controlling and managing your digital identity while proving 
			who you are in a digitally secure way,” says Callum Murray, CEO of Amiqus. Founded in 2015, 
			Amiqus leverages the principles of privacy by design, alongside machine learning and advanced data analytics, to 
			radically quicken the speed and strengthen the rigour of authenticating a person without physical paperwork being 
			exchanged.
		</p> 
		<p>
			Early adopters are in high-stakes or high-transaction fields such as financial services, law and property 
			conveyancing. Accounting firms spend on average four days a month on paper-based compliance, much of which can 
			be eliminated, while recruiters can shorten weeks of pre-employment paperwork into minutes of remote vetting, 
			enabling candidates to complete checks from <a target="_blank" href="https://amiqus.co/sectors/recruitment">their own devices</a>.
		</p>
		<p>
			The company sees itself as a compliance platform with far wider use cases, especially in the context of covid-19. 
			As public services came under pressure to recruit vital roles as quickly and safely as possible, Mr Murray’s team 
			worked with the Scottish government and NHS Business Services Authority to help speed up recruitment. An 
			Edinburgh-based campervan company recently used Amiqus to review driving licences remotely, which enabled iti to 
			provide insurance to key workers, a service it pivoted to during lockdown. “We are a horizontal player across 
			sectors, providing the fundamentals of securing and managing sensitive information,” says Mr Murray.
		</p>
		<p>
			<span>All eyes on startups</span>
		</p>
		<p>
			Amiqus is part of a wave of UK startups and SMEs at the vanguard of digital and cybersecurity. One <a target="_blank" href="https://cyberexchange.uk.net/database/">
			estimate</a> by 
			Cyber Exchange and Dealroom.co has identified more than 1,000 cyber startups, SMEs and corporates in the UK. The 
			UK topped the <a target="_blank" href="https://news.itu.int/cgi-2019-released/">ITU Global Cybersecurity Index 2019</a>, which 
			measures government responses to cyber defence. It also 
			ranked third, after the US and China, in the Harvard Kennedy School’s <a target="_blank" href="https://www.belfercenter.org/publication/national-cyber-power-index-2020">
			National Cyber Power Index 2020</a>. The 
			government has invested in its startup community, notably through a £1.9bn tranche of ‘transformation’ funding 
			in the <a target="_blank" href="https://www.gov.uk/government/news/britains-cyber-security-bolstered-by-world-class-strategy?utm_source=economist&utm_medium=display&utm_content=article-cybersecurity&utm_campaign=rtt">
			2016-2021 national cyber strategy</a>
			. Homegrown successes span compliance-related regtech and lawtech to 
			artificial intelligence (AI)-based cyber-threat detection players, most notably Darktrace, the globally renowned 
			Cambridge-based tech unicorn. 
		</p>
		<p>
			Mr Murray identifies three advantages to the UK ecosystem. One is the size and international reputation of the 
			financial, wealth management and legal industries, a major client base for Amiqus. "Solicitors in the UK are 
			highly regarded internationally, so it’s understood that if we are trusted as a partner to this community, then 
			we can be relied on by others." 
		</p>
		<p>
			The second is the interlocking of its fintech, cyber and digital security communities, providing plenty of scope 
			for collaboration, idea sharing and mutual aid. Mr Murray cites organisations including the 
			<a target="_blank" href="https://fintech-alliance.com/">FinTech Alliance</a> and 
			<a target="_blank" href="https://www.fintechscotland.com/">FinTech Scotland</a> as enabling companies to “come together, 
			share insights and partner”. The strong global profile 
			of UK digital security and compliance startups is in turn helping raise awareness in the corporate community. 
			"Companies are seeing the benefits of digital technology and of providing a better customer experience by working 
			with early-stage tech companies," says Mr Murray. 
		</p>
		<p>
			The third enabler is the engagement of the public sector. Regulations to tackle financial crime have helped 
			foster a more responsible business environment in the UK, but the challenge can be overwhelming in terms of 
			working hours and resources, especially for smaller companies. This is pushing more and more companies to look 
			at what role automation, AI and advanced analytics could play in ensuring companies stay onside. Rules making 
			professional standards bodies accountable for compliance failures among their members has led these organisations 
			to look more closely at their community, especially smaller firms, and at whether they have a sufficient technology 
			strategy. 
		</p>
		<p>
			The second public-sector dynamic is the procurement opportunity of the UK government itself. Public agencies are 
			trying to widen their vendor network to include more SMEs and startups, to harness innovation and boost economic 
			dynamism by using public spending to help promising UK businesses scale up. The CivicTech Alliance, formed in 
			Scotland, is working across 16 Commonwealth countries to promote access for early-stage and SME businesses to 
			government procurement opportunities. The UK government’s <a target="_blank" href="https://www.gov.uk/digital-marketplace?utm_source=economist&utm_medium=display&utm_content=article-digital-marketplace&utm_campaign=rtt">
			Digital Marketplace</a> is making it easier for public-sector 
			organisations to identify people and technology for their digital projects, from cloud hosting and data centres 
			to digital specialists. 
		</p>
		<p>
			Amiqus is among those building a public-sector client roster, recently securing a contract with the NHS Business 
			Services Authority to provide rapid pre-employment vetting to an estimated 5,000 returning clinicians as part of 
			the covid-19 effort. Mr Murray thinks earlier-stage high-growth tech companies provide unique value because their 
			software enables them to scale quickly and flexibly. 
		</p> 

		<p>
			"This is a team of around 30 people, like an R&D unit you can drop in. We get a chance to scale up our technology 
			and the public sector gets to deploy solutions swiftly," he says. Working with a smaller company to deliver a 
			specific service is a paradigm shift from the large-scale IT ‘change’ programmes on which governments long relied. 
			"These can quickly run into the tens of millions of pounds. Instead, you can now bring in a smaller partner that 
			is purpose-driven and can quickly deliver what you need."
		</p> 
	`,
	contentTwo: `
		<p>
		<span>Taking UK expertise global</span>
		</p>
		<p>
			The Department for International Trade (DIT) is part of wider government efforts to support innovative UK digital 
			security companies to take their ideas global. Dr Henry Pearson OBE, cybersecurity ambassador for UK Defence and 
			Security Exports at DIT, sees the department as a trusted broker that can help UK startups and SMEs build their 
			business internationally, which is not easy in a sector such as cyber. 
		</p>
		<p>
			“Once you start talking about the particular problems a country is suffering and its requirements, that can get 
			very sensitive and that conversation requires a degree of trust to make it work,” he says. DIT supported Amiqus 
			on a legal technology export mission to Australia, Austria and Switzerland in 2018.
		</p> 
		<p>
			Dr Pearson also cites as important the network of government-funded accelerator programmes for helping startups 
			develop and test ideas and match their innovations to the needs of the market. Cyber 101, a government-funded 
			initiative, supports UK cyber startups and connects them with industry mentors, corporate buyers, peers and investors. 
			The London Office for Rapid Cybersecurity Advancement <a target="_blank" href="https://www.lorca.co.uk/">(LORCA)</a>, funded by the 
			UK Department for Digital, Culture, 
			Media & Sport, is an accelerator helping innovative cybersecurity companies that are solving digital challenges 
			faced by enterprises and the wider economy and society. Now in its fifth cohort, LORCA, with a range of delivery 
			partners including Plexal and Deloitte, helps connect startups to investors and industry partners. The National 
			Cyber Security Centre’s (NCSC) accelerator in Cheltenham solicits participant startups through a competition 
			based on solving real-world cyber challenges facing industry. Startups get access to experts from the NCSC who 
			can “provide technical support and explain the problems the industry is actually having,” says Dr Pearson. 
			“Companies often have the germs of ideas, but it is about matching those to real problems that companies will 
			pay money to have solved.” 	
		</p>
	`,
	additionalBottomText:
		`<p>Produced for DIT by (E) BrandConnect, a commercial division of The Economist Group, which operates 
		separately from the editorial staffs of The Economist and The Economist Intelligence Unit. Neither (E) BrandConnect 
		nor its affiliates accept any responsibility or liability for reliance by any party on this content.</p>`
}
