/*  Data imports    */
import pageData from '../../constants/pages/story-4';

import config from '../../config/config';
import classes from '../../assets/scss/pages/story.module.scss';

import React from "react";
import Layout from '../../components/Layout/Layout';
import Seo from "../../components/Seo/Seo";
import {withPrefix} from 'gatsby';
import HeroSecondSection from '../../components/Sections/HeroSecondSection/HeroSecondSection';
import StorySelector from '../../components/StorySelector/StorySelector';
import SliderSection from '../../components/Sections/SliderSection/SliderSection';
import {useWindowWidth} from '@react-hook/window-size/throttled';
import CardSectionSec from '../../components/Sections/CardSectionSec/CardSectionSec';
import Content from '../../components/Content/Content';
import MessageWithImgSection from '../../components/Sections/MessageWithImgSection/MessageWithImgSection';
import {Page} from '../../components/Page';
import UtilBranLock from '@economist/fabric-components/utility/utilbranlock/UtilBranLock';
import ScrollDepth from '@economist/fabric-components/utility/ScrollDepthTracker/ScrollDepth';
import UtilWebVitals from '@economist/fabric-components/utility/utilwebvitals/UtilWebVitals';

const StoryPage = () => {
	const windowWidth = useWindowWidth();

	const heroSectionOne = pageData.heroSectionOne
		? <HeroSecondSection
			authorComponent={
				<UtilBranLock
					alt="written by logo"
					text=""
					logo={withPrefix('/images/innovation-is-great.png')}
				/>}
			fullScreen={true}
			position="left"
			data={pageData.heroSectionOne}/>
		: null;

	const cardSectionOne = pageData.cardSectionOne
		? <CardSectionSec
			fullScreen={true}
			position={pageData.cardSectionOne.position || 'left'}
			data={pageData.cardSectionOne}/>
		: null;

	const contentOne = pageData.contentOne
		? <Content>{pageData.contentOne}</Content>
		: null;

	const messageWithImgSectionOne = pageData.messageWithImgSectionOne
		? <MessageWithImgSection addBackgroundCard={pageData.addBackgroundCard || false}
		                         data={pageData.messageWithImgSectionOne}/>
		: null;

	const contentTwo = pageData.contentTwo
		? <Content>{pageData.contentTwo}</Content>
		: null;

	const sliderSectionOne = pageData.sliderSectionOne
		? <SliderSection
			scale={.2}
			headerImgPath={pageData.sliderSectionOne.headerImgPath || false}
			headerImgAlt={pageData.sliderSectionOne.headerImgAlt || false}
			settings={pageData.sliderSectionOne.settings}
			items={pageData.sliderSectionOne.items}
		/>
		: null;

	const cardSectionTwo = pageData.cardSectionTwo
		? <CardSectionSec position={pageData.cardSectionTwo.position || 'left'} indentBottom={false}
		                  data={pageData.cardSectionTwo}
											authorComponent={
												<UtilBranLock
													alt="written by"
													text="written by"
													alignMobile="center"
													logo={withPrefix('/images/svg/EIUSVG.svg')
													}/>
											}/>
		: null;

	const additionalText = pageData.additionalBottomText
		? <div dangerouslySetInnerHTML={{__html: pageData.additionalBottomText}} className={classes.additionalText}/>
		: null;

	return (
		<>
		<Seo title={pageData.pageTitle} description={pageData.pageDescription}/>
		<Page>
			<Layout invertMenu={windowWidth <= config.mobileWidth}
			        menuIndentDesktop={70}
			        menuChild={
				        windowWidth <= config.mobileWidth
					        ? heroSectionOne
					        : null
			        }>
				<UtilWebVitals/>
				<ScrollDepth/>

				<div style={{maxWidth: "1440px", margin: "0 auto"}}>

					<div className={classes.columnsContainer}>
						<div className={classes.columnSmall}>
							<StorySelector/>
						</div>

						<div className={classes.columnBig}>
							{
								windowWidth > config.mobileWidth
									? heroSectionOne
									: null
							}

							{cardSectionOne}

							{contentOne}

							{messageWithImgSectionOne}

							{contentTwo}

							{sliderSectionOne}

						</div>
					</div>

					{cardSectionTwo}

					{additionalText}

				</div>

			</Layout>
		</Page>

		</>
	)
};

export default StoryPage;
